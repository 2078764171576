import {CurrentUser} from "../Auth/Services/CurrentUser";
import {Dropdown, Menu, Space} from "antd";
import {UserOutlined, LogoutOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {TrackSignOut, TrackVisitProfile} from "../../utils/Amplitude";


export const TopMenuProfile = () => {
    const user = CurrentUser()
    let history = useHistory()

    function handleMenuClick(value: any): void {
        if(value.key==='LOGOUT'){
            TrackSignOut('top_menu_profile')
            CurrentUser()?.signOut();
            history.push('signin')
        }else if (value.key==='PROFILE') {
            TrackVisitProfile('top_menu_profile')
            history.push('profile')
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="PROFILE" icon={<UserOutlined />}>
                Profile
            </Menu.Item>
            <Menu.Item key="LOGOUT" icon={<LogoutOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    );

    if(user){
        return(
            <Space wrap>
                <Dropdown.Button overlay={menu} placement="bottomCenter" icon={<UserOutlined />}>
                    {user?.getUsername()}
                </Dropdown.Button>
            </Space>
        )
    }else {
        return(null)
    }
}