import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import axios from 'axios';
import {Divider, Layout} from 'antd';
import './Home.css'
import LastUpdate from '../../components/LastUpdate';
import {SignalSelector} from '../../components/SignalSelector/SignalSelector';
import {Content} from 'antd/lib/layout/layout';
import TopMenu from '../../components/TopMenu/TopMenu';
import AppFooter from '../../components/AppFooter/AppFooter';
import {HomeColumns} from "../../components/SignalTable/HomeColumns";
import {SignalTable} from "../../components/SignalTable/SignalTable";
import {isUserPremium} from "../../utils/User";
import {configRequest} from "../../utils/ConfigRequest";
import {TrackClickSignalSelector, TrackVisitHome} from "../../utils/Amplitude";


interface IState {
    signals: any;
    date: any;
}

export default class Home extends React.Component<RouteComponentProps, IState> {
    pagination = {label: 'none', value: 'none'}

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {signals: [], date: null}
        this.signalHandler = this.signalHandler.bind(this);
        this.fetch = this.fetch.bind(this);
        this.fetch()
        TrackVisitHome()
    }

    public render() {
        const {
            commodities = [],
            indices = [],
            currencies = [],
            stocks = []
        } = this.state.signals;
        return (
            <Layout>
                <TopMenu/>
                <Content className="site-layout-background">
                    <div style={ {padding: '1%'} }>
                        <LastUpdate date={ this.state.date } isPremium={isUserPremium()}/>
                        <div>
                            <SignalSelector onChange={ this.signalHandler }/>
                        </div>
                        <Divider>Commodities</Divider>
                        <SignalTable columns={HomeColumns('commodities')} data = {commodities.all}></SignalTable>
                        <Divider>Currencies</Divider>
                        <SignalTable columns={HomeColumns('currencies')} data = {currencies.major}></SignalTable>
                        <Divider>Indices</Divider>
                        <SignalTable columns={HomeColumns('indices')} data = {indices.major}></SignalTable>
                        <Divider>Stocks</Divider>
                        <SignalTable columns={HomeColumns('stocks')} data = {stocks.major}></SignalTable>
                    </div>
                </Content>
                <AppFooter/>
            </Layout>)
    }

    fetch(version?: any) {
        const path = (!!version) ? `signal_version?version=${ version }` : 'last_signals'
        this.setState({date: null})
        axios.get(`${ process.env.REACT_APP_API_URL }/${ path }`, configRequest(), ).then(Response => {
            this.setState({
                signals: Response.data.body.signals,
                date: Response.data.body.datetime
            })
        })
    }

    signalHandler(version: any) {
        TrackClickSignalSelector('home')
        this.fetch(version)
    }
}
