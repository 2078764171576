import {CurrentUserData} from "../Auth/Services/CurrentUser";
import Title from "antd/es/typography/Title";
import {Card, Col, Divider, Row} from "antd";
import {TrackSubscription} from "../../utils/Amplitude";

export default function SubscriptionContainer() {
    let email: string = '';
    CurrentUserData({
        callbackFunction: (attributes: { [k: string]: string }) => {
            email = attributes['email']
        }
    })

    const openCheckout = (id: number) => {
        // @ts-ignore
        Paddle.Checkout.open({
            product: id,
            email: email,
            successCallback: (data: any) => {
                TrackSubscription('subscribe', 'success')
                window.location.href = "/signin"
            },
            closeCallback: (data: any) => {
                TrackSubscription('subscribe', 'closed')
                // console.log(data)
            },
        });
    }

    const Plan = (title: string, description: string, id: number, price: number, months: number) => {
        return (
            <div style={{flex: 1}}>
                <Card title={title}
                      style={ {
                          textAlign: 'center',
                          maxWidth: 300,
                          minHeight: 400,
                          float: 'left',
                          margin: 5
                } }>
                    <div style={{minHeight: 200}}>
                        <Title level={2} style={ {textAlign: 'center'} }>{price}$ / {months} months </Title>
                        <h2 style={ {textAlign: 'center'} }>{description}</h2>
                    </div>
                    <Divider/>
                    <button style={ {textAlign: 'center'} } onClick={ e => {
                        openCheckout(id)
                    } } id='buy' className='paddle_button' data-product={ id }>
                        Subscribe!
                    </button>
                </Card>
            </div>
        )
    }

    return (
        <div>
            <script
                onLoad={ e => {
                    // @ts-ignore
                    Paddle.Setup({vendor: 140605});
                } }
                src='https://cdn.paddle.com/paddle/paddle.js'
            />
            <div style={ {
                fontSize: 14,
                margin: 'auto',
                padding: 30,
            } }>
                <Title style={ {textAlign: 'center'} }><b>Choose</b> your plan and try it free for 7 days!</Title>
                <Divider/>
                <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center'
                }}>
                    { Plan('Starter', 'Monthly Subscription', 762334, 79.00, 1) }
                    { Plan('Trader', 'Quarterly Subscription (SAVE 10% !)', 762335, 213.00, 3) }
                    { Plan('Profit Generator', 'Biannually Subscription (SAVE 20% !)',762336, 379.00, 6) }
                    { Plan('Serious Money maker', 'Annually Subscription (SAVE 40% !)',762337, 568.00, 12) }
                </div>
            </div>
        </div>
    );
}
