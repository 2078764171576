import {Form, Input, Button, Checkbox, Divider} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import './SignInForm.css'
import {CognitoAuth} from "../Services/CognitoAuth";
import { useHistory } from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import {setSessionWithExpiry} from "../../../utils/User";

export const SignInForm = (props: any) => {
  let history = useHistory()
  const onFinish = (values: any) => {
    CognitoAuth(values, () => {
      setSessionWithExpiry(true)
      history.push('/')
    })
  }
  return (
    <div style={{paddingLeft: '25%', paddingRight: '25%', paddingTop: 100}}>
      <ToastContainer/>
      <Title>Login</Title>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username / email!' }]}
          initialValue={props.email || props.username || ''}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />

        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" onClick={() => history.push('forgot')} href='forgot'>
            Forgot password
          </a>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Form.Item>
        <Divider/>
        <Form.Item> Or <a href="signup">register now!</a> </Form.Item>
      </Form>
    </div>
  );
}

export default SignInForm;