import axios from 'axios';
import {CurrentUser, CurrentUserAccessToken, CurrentUserData} from "../components/Auth/Services/CurrentUser";
import {configRequest} from "./ConfigRequest";

const KEY = 'Session'
export const getUserSession = () => {
    const session = getSessionWithExpiry()

    return session
};

export const isUserPremium = () => {
    const userSession = getUserSession()
    const isPremium = parseInt(userSession?.is_premium) == 1

    return isPremium
}

export const setSessionWithExpiry = (force: boolean) => {
    const session = force ? false : getUserSession()
    if(force || !session){
        axios.get(`${ process.env.REACT_APP_API_URL }/session`, configRequest()).then(Response => {
            const value = Response.data.body
            const now = new Date()
            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: value,
                expiry: now.getTime() + 60000,
            }
            localStorage.setItem(KEY, JSON.stringify(item))
        })
    }
}
export const getSessionWithExpiry = () => {
    let item = getSessionCache()
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (!item || now.getTime() > item.expiry) {
        setSessionWithExpiry(true)
    }

    return item?.value
}

const getSessionCache = () => {
    const itemStr = localStorage.getItem(KEY)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)

    return item
}
