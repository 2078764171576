import { Button, Form, Input } from 'antd';
import {CognitoUser} from 'amazon-cognito-identity-js';
import UserPool from '../Services/UserPool';
import { LockOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';


export const ConfirmationForm = (props: any) => {
  let history = useHistory();
  const userData = {
    Username: props.username,
    Pool: UserPool,
  };
  const cognitoUser = new CognitoUser(userData);
  const confirmRegistration = (values: any) => {
    const {code} = values
    cognitoUser.confirmRegistration(code, true, function(err, result) {
      if (err) {
        toast.error(err.message, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
    } else {
        toast.success('Congratulations, you are now a user of Trading Advisor!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => history.push(`signin?email=${props.email}`),
          onClick: () => history.push(`signin?email=${props.email}`),
        });
    }
    });
  }

  return(
    <div style={{paddingLeft: '25%', paddingRight: '25%', paddingTop: 100}}>
      <Title>Confirm Registration</Title>
      <Title level={2}>{`Hi ${props.username}! Please check your email ${props.email} and copy here the registration code we sent you.`}</Title>
      <ToastContainer/>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={confirmRegistration}
      >
        <Form.Item
          name="code"
          rules={[{ required: true, message: 'Please input your registration code.' }]}
        >
          <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Registration Code" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}