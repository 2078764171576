import {Select} from 'antd';
import axios from 'axios';
import {Key} from 'rc-select/lib/interface/generator';
import React from 'react';
import {isUserPremium} from "../../utils/User";

const {Option} = Select;
type IState = {
    selectorType: string;
    signalVersions: any,
    isPremium: boolean
}

export class SignalSelector extends React.Component<any, any> {

    constructor(props: IState) {
        super(props);

        this.state = {
            signalVersions: [{'datetime': 'Current date', version: 'none'}],
            selectorType: 'SIGNALS',
            isPremium: isUserPremium()
        }
    }

    public componentDidMount(): void {
        if (this.state.isPremium) {
            axios.get(`${ process.env.REACT_APP_API_URL }/last_signals_versions`).then(Response => {
                let versions = Response.data.body
                versions.forEach((value: string, index: number) => {
                    versions[index]['datetime'] = new Date(versions[index]['datetime']).toString()
                });
                this.setState({signalVersions: versions})
            })
        }
    }

    public render() {
        if (this.state.isPremium) {
            return (
                <Select defaultValue={ this.state.signalVersions?.[0]?.['version'] }
                        style={ {width: 300} }
                        onChange={ this.props.onChange }>
                    { this.state.signalVersions?.map((item: { version: Key; datetime: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                        <Option value={ item.version }>{ item.datetime }</Option>
                    )) }
                </Select>)
        }else {
            return(<div/>)
        }

    }
}

