import * as React from "react";
import Paragraph from "antd/lib/typography/Paragraph";


export const InstrumentColumn = [
    {
        title: 'Date',
        dataIndex: 'datetime',
        key: 'datetime',
        render: (date: string) => {
            return <Paragraph>{ new Date(date.replace(/\s/, 'T')).toString() }</Paragraph>
        }
    },
    {
        title: 'Operation',
        dataIndex: 'Operation',
        key: 'operation',
        ellipsis: true,
    },
    {
        title: 'Patterns',
        dataIndex: 'Patterns',
        key: 'patterns',
        ellipsis: true,
        responsive: ["md" as const],
    },
    {
        title: 'Price',
        dataIndex: 'current_price',
        key: 'current_price',
    },
    {
        title: 'Stop Loss',
        dataIndex: 'stop_loss',
        key: 'stop_loss',
        render: (text: string) => {
            return <Paragraph copyable strong type="danger">{ text }</Paragraph>
        },
    },
    {
        title: 'Take Profit 1',
        dataIndex: 'take_profit',
        key: 'take_profit_2',
        render: (text: string) => {
            return <Paragraph copyable strong type="success">{ text }</Paragraph>
        }
    },
    {
        title: 'Take Profit 2',
        dataIndex: 'take_profit_2',
        key: 'take_profit_2',
        render: (text: string) => {
            return <Paragraph copyable strong type="success">{ text }</Paragraph>
        }
    },
    {
        title: 'Gain %',
        dataIndex: 'possible_gain',
        key: 'possible_gain',
        sorter: (a: { possible_gain: number, possible_loss: number; }, b: { possible_gain: number, possible_loss: number; }) => a.possible_gain - b.possible_gain,
        render: (text: string) => {
            return <Paragraph strong type="success">{ text } %</Paragraph>
        },
        responsive: ["md" as const],
    },
    {
        title: 'Loss %',
        dataIndex: 'possible_loss',
        key: 'possible_loss',
        sorter: (a: { possible_gain: number, possible_loss: number; }, b: { possible_gain: number, possible_loss: number; }) => a.possible_loss - b.possible_loss,
        render: (text: string) => {
            return <Paragraph strong type="danger">{ text } %</Paragraph>
        },
        responsive: ["md" as const],
    },
];