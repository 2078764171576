
import {Button, Form, Input} from "antd";
import React from "react";
import Title from "antd/lib/typography/Title";
import {toast, ToastContainer} from "react-toastify";
import {useHistory} from "react-router-dom";
import {CurrentUser} from "../Services/CurrentUser";
import {TrackChangePassword} from "../../../utils/Amplitude";


export const ProfileForm = () => {
    let history = useHistory()
    const user = CurrentUser();
    const changePassword = (values: {oldPassword: string; newPassword: string, confirmedPassword: string}) => {
        const {oldPassword, newPassword} = values
        user?.getSession(() => {
            user?.changePassword(oldPassword, newPassword, function(err) {
                if (err) {
                    TrackChangePassword('profile', 'error')
                    toast.error(err.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        draggable: true,
                    })
                } else {
                    TrackChangePassword('profile', 'success')
                    toast.success('Password was successfully changed.', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        draggable: true,
                        onClose: () => history.push('signin'),
                        onClick: () => history.push('signin'),
                    })
                }
            });
        })

    }

    return(
        <div style={{paddingLeft: '25%', paddingRight: '25%', paddingTop: 100}}>
            <ToastContainer/>
            <Title level = {1} style={{textAlign: 'center'}}>Change password</Title>
            <Form onFinish={changePassword}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}>
                <Form.Item
                    name="oldPassword"
                    label="Old Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirmedPassword"
                    label="Confirm new password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Confirm new password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}