import {Footer} from "antd/lib/layout/layout";
import React from "react";
import {HeartTwoTone} from '@ant-design/icons';
import {Divider} from "antd";

export default class AppFooter extends React.Component {
    public render(): JSX.Element {
        return (
            <Footer>
                <div style={ {textAlign: 'center'} }>
                    <span>
                      Made with <HeartTwoTone twoToneColor="#eb2f96"/> by <a href="https://twitter.com/Jota_Segovia"
                                                                             target="_blank" rel="noreferrer">Javier Segovia</a>.
                    </span>
                    <Divider/>
                    <span>
                        Contact us
                        <p><a href="mailto:admin@tradingadvisor.app" target="_blank" rel="noreferrer">admin@tradingadvisor.app</a></p>
                    </span>
                    <Divider/>
                    <span>
                        <p><a href="terms-of-service" target="_blank" rel="noreferrer">Terms of service</a></p>
                    </span>
                    <span>
                        <p><a href="privacy-policy" target="_blank" rel="noreferrer">Privacy Policy </a></p>
                    </span>
                </div>
            </Footer>
        );
    }
}
