import * as React from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import {isUserPremium} from "../../utils/User";
import {UpgradeModal} from "../Subscription/UpgradeModal";
import {TrackClickInstrumentName} from "../../utils/Amplitude";

export const HomeColumns = (type: string) => {
    const nameAction = (text: string) => {
        TrackClickInstrumentName('home')
        if (isUserPremium()){
            const queryParams = {name: text, type: type}
            const queryString = new URLSearchParams(queryParams).toString()
            const URL = `/instrument?${ queryString }`
            return <a href={ URL }>{ text }</a>
        } else {
            const onCLick = () => {
                return UpgradeModal()
            }
            return <a onClick={ onCLick }>{ text }</a>
        }
    }

    return ([
        {
            title: 'Name',
            dataIndex: 'Index',
            key: 'index',
            render: (text: string) => {
                return nameAction(text)
            }
        },
        {
            title: 'Symbol',
            dataIndex: 'Symbol',
            key: 'symbol',
            responsive: ["md" as const],
        },
        {
            title: 'Operation',
            dataIndex: 'Operation',
            key: 'operation',
            ellipsis: true,
        },
        {
            title: 'Patterns',
            dataIndex: 'Patterns',
            key: 'patterns',
            ellipsis: true,
            responsive: ["md" as const],
        },
        {
            title: 'Price',
            dataIndex: 'current_price',
            key: 'current_price',
        },
        {
            title: 'Stop Loss',
            dataIndex: 'stop_loss',
            key: 'stop_loss',
            render: (text: string) => {
                return <Paragraph copyable strong type="danger">{ text }</Paragraph>
            },
        },
        {
            title: 'Take Profit 1',
            dataIndex: 'take_profit',
            key: 'take_profit_2',
            render: (text: string) => {
                return <Paragraph copyable strong type="success">{ text }</Paragraph>
            }
        },
        {
            title: 'Take Profit 2',
            dataIndex: 'take_profit_2',
            key: 'take_profit_2',
            render: (text: string) => {
                return <Paragraph copyable strong type="success">{ text }</Paragraph>
            }
        },
        {
            title: 'Gain %',
            dataIndex: 'possible_gain',
            key: 'possible_gain',
            sorter: (a: { possible_gain: number, possible_loss: number; }, b: { possible_gain: number, possible_loss: number; }) => a.possible_gain - b.possible_gain,
            render: (text: string) => {
                return <Paragraph strong type="success">{ text } %</Paragraph>
            },
            responsive: ["md" as const],
        },
        {
            title: 'Loss %',
            dataIndex: 'possible_loss',
            key: 'possible_loss',
            sorter: (a: { possible_gain: number, possible_loss: number; }, b: { possible_gain: number, possible_loss: number; }) => a.possible_loss - b.possible_loss,
            render: (text: string) => {
                return <Paragraph strong type="danger">{ text } %</Paragraph>
            },
            responsive: ["md" as const],
        },
    ]);
}
