import * as React from 'react';
import './App.css';
import {Switch, Route, withRouter, RouteComponentProps, Redirect} from 'react-router-dom';
import ReactGA from 'react-ga';
import Home from './pages/Home/Home';
import Instrument from './pages/Instrument/Instrument';
import SignIn from './pages/Auth/SignIn/SignIn';
import SignUp from './pages/Auth/SignUp/SignUp';
import Confirmation from './pages/Auth/Confirmation/Confirmation';
import {CurrentUser} from "./components/Auth/Services/CurrentUser";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Profile from './pages/Auth/Profile/Profile';
import PrivacyPolicy from "./pages/Consent/PrivacyPolicy";
import TermsOfService from "./pages/Consent/TermsOfService";
import Landing from "./pages/Home/Landing";
import Subscribe from './pages/Subscribe';
import {isUserPremium, setSessionWithExpiry} from "./utils/User";


class App extends React.Component<RouteComponentProps<any>> {

  componentDidMount() {
    document.title = "Trading Advisor App"
    ReactGA.initialize("G-CKWTDCSWF7");
    setSessionWithExpiry(false)
  }

  public render(): JSX.Element {
      const currentUser = CurrentUser()
      if(currentUser){
          if (isUserPremium()){
              return(
                  <Switch>
                      <Route path={'/'} exact component={Home} />
                      <Route path={'/instrument'} exact component={Instrument} />
                      <Route path={'/privacy-policy'} exact component={PrivacyPolicy} />
                      <Route path={'/terms-of-service'} exact component={TermsOfService} />
                      <Route path={'/profile'} exact component={Profile} />
                      <Redirect to="/" />
                  </Switch>)
          }
          return(
              <Switch>
                  <Route path={'/'} exact component={Home} />
                  <Route path={'/privacy-policy'} exact component={PrivacyPolicy} />
                  <Route path={'/terms-of-service'} exact component={TermsOfService} />
                  <Route path={'/profile'} exact component={Profile} />
                  <Route path={'/subscribe'} exact component={Subscribe} />
                  <Redirect to="/" />
              </Switch>);
      }
      return (
          <Switch>
              <Route path={'/signin'} exact component={SignIn} />
              <Route path={'/signUp'} exact component={SignUp} />
              <Route path={'/confirmation'} exact component={Confirmation} />
              <Route path={'/forgot'} exact component={ForgotPassword} />
              <Route path={'/terms-of-service'} exact component={TermsOfService} />
              <Route path={'/privacy-policy'} exact component={PrivacyPolicy} />
              <Route path={'/landing'} exact component={Landing} />
              <Redirect to="/landing"/>
          </Switch>
      );

  }
}
export default withRouter(App);