import React from "react";
import axios from "axios";
import {InstrumentChart} from "./InstrumentChart";

interface IState {
    type: string;
    name: string;
    interval: any;
    from: any;
    to: any;
    data: any;
    timezone: any;
    signalData: any;
}

export class Chart extends React.Component<any, IState> {
    constructor(props: IState) {
        super(props);
        this.state = {
            type: props.type,
            name:  props.name,
            interval:  props.interval,
            from: props.from,
            to: props.to,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            data: null,
            signalData: null
        }
    }

    componentDidMount() {
        const parameters = {
            type: this.props.type,
            name:  this.props.name,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const url = `${ process.env.REACT_APP_API_URL }/instrument_data?${ new URLSearchParams(parameters).toString() }`
        axios.get(url).then(Response => {
            this.setState({data: Response.data.body})
        })
    }

    render() {
        if(this.state.data){
            return(
                <div>
                    <InstrumentChart initialData={ this.state.data } signalData={ this.props.signalData }/>
                </div>
            )
        } else {
            return(<div/>)
        }

    }
}
