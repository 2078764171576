import React, { FunctionComponent } from 'react';
import { AlertFilled } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {Button, Skeleton} from 'antd';
import {UpgradeButtonAction} from "../pages/Subscribe";

type LastUpdateProps = {
  date: String,
  isPremium: boolean
}

export const LastUpdate: FunctionComponent<LastUpdateProps> = ({date, isPremium}) =>
{
  let freemiumMessage = null
  let delayedText = null
  if(!isPremium){
    freemiumMessage =
    <div style={{
      backgroundColor: 'lightcyan',
      border: 5,
      borderColor: 'black',
      padding: 10,
      alignItems: 'vertical'
    }}>
      <h2 style={{textAlign: 'center'}}>
        To get the most recent signals you should   &nbsp;
        <Button type="primary" onClick={() => UpgradeButtonAction('last_update_banner')}>UPGRADE</Button>
      </h2>
    </div>
    delayedText = "  (DELAYED)"
  }
  if(date) {
    return(
      <div>
        <Title
            level={4}
            style={{ padding: 10 }}>
          Last update: {new Date(date.replace(/\s/, 'T')).toString()} {delayedText}
          <AlertFilled style={{ color: '#eed202' }} />
        </Title>
        {freemiumMessage}
      </div>

    )
  } else {
    return(
      <Skeleton.Input style={{ width: 200 }} active={true} size={'large'} />
    )
  }
};

export default LastUpdate;
