import { Layout } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TopMenu from '../../../components/TopMenu/TopMenu';
import SignUpForm from '../../../components/Auth/SignUpForm/SignUpForm';
import { Content } from 'antd/lib/layout/layout';

export default class SignIn extends React.Component<RouteComponentProps> {

  public render() {
    return (
      <Layout style={{height: '100vh'}}>
        <TopMenu/>
        <Content>
          <SignUpForm/>
        </Content>
      </Layout>
    );
  }
}
