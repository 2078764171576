import {Button, Divider, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import Title from "antd/lib/typography/Title";
import {toast, ToastContainer} from "react-toastify";
import {CognitoUserInstance} from "../Services/CognitoUserInstance";
import {useHistory} from "react-router-dom";


export const ForgotPasswordForm = () => {
    let history = useHistory()
    let username = '';
    const RequestPasswordReset = (values: {username: string}) => {
        username = values.username;
        const user = CognitoUserInstance(username)
        user.forgotPassword({
            onFailure(err: Error): void {
                toast.error(err.message, {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    draggable: true,
                })
            },
            onSuccess: (data) => {
                toast.success('Check your email and copy the code we sent you.', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    draggable: true,
                })
            }
        })
    }

    const ConfirmPassword = (values: {code: string, password: string, confirmedPassword: string}) => {
        const {code, password} = values
        if(username) {

        } else {
            toast.error('You should request a password reset first.', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                draggable: true,
            })
        }

        CognitoUserInstance(username).confirmPassword(code, password, {
            onSuccess() {
                toast.success('Password was successfully changed.', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    draggable: true,
                    onClose: () => history.push('signin'),
                    onClick: () => history.push('signin'),
                })

            },
            onFailure(err) {
                toast.error(err.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    draggable: true,
                })
            },
        });
    }

    return(
        <div style={{paddingLeft: '25%', paddingRight: '25%', paddingTop: 100}}>
            <ToastContainer/>
            <Title level = {1} style={{textAlign: 'center'}}>Forgot password</Title>
            <Divider/>
            <Title level = {2} style={{textAlign: 'left'}}>Reset</Title>
            <Form onFinish={RequestPasswordReset}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}>
                <Form.Item
                    name="username"
                    label='Username or Email'
                    rules={[{ required: true, message: 'Please input your Username / email!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="my-awesome-username / awesome@email.com" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Request password reset.
                    </Button>
                </Form.Item>
            </Form>
            <Divider/>
            <Title level = {2} style={{textAlign: 'left'}}>Confirm</Title>
            <Form onFinish={ConfirmPassword}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}>
                <Form.Item
                    name="code"
                    label="Code"
                    rules={[{ required: true, message: 'Please input the code sent it to your email!' }]}>
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" placeholder={'Code'}/>}
                        placeholder="Code">
                    </Input>
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Confirm new password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}