import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import {TrackClickUpgrade} from "../../utils/Amplitude";
import {UpgradeButtonAction} from "../../pages/Subscribe";

export const UpgradeModal = () => {
    Modal.confirm({
        title: "Attention",
        content: (
            <div>
                <h3 style={{textAlign: 'center'}}>
                    You can't perform this action, to use this feature you should subscribe
                </h3>
            </div>
        ),
        okText: 'Subscribe',
        onOk() {UpgradeButtonAction('upgrade_modal')},
        onCancel() {}
    });
};