import {
  Form,
  Input,
  Checkbox,
  Button, Divider,
} from 'antd';
import UserPool from '../Services/UserPool';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};



export const SignUpForm = () => {
  const [status, setStatus] = useState('PENDING');
  const history = useHistory();
  const onFinish = (values: any) => {
    if(status !== 'SUCCESS') {
      const {email, password, name, nickname} = values
      const attributeList = [];
      attributeList.push(new CognitoUserAttribute({ Name: 'name', Value: name }))
      attributeList.push(new CognitoUserAttribute({Name: 'nickname', Value: nickname}))
      attributeList.push(new CognitoUserAttribute({Name: 'email', Value: email}))
      UserPool.signUp(nickname, password, attributeList, [], function (err, result) {
        if (err) {
          setStatus('ERROR')
          toast.error(err.message, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setStatus('SUCCESS')
          toast.success('Check your email to finish the registration.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            onClose: () => history.push(`confirmation?username=${nickname}&email=${email}`),
            onClick: () => history.push(`confirmation?username=${nickname}&email=${email}`),
          });
        }
      });
    }
  };

  const [form] = Form.useForm();
  return (
    <div style={{paddingLeft: '20%', paddingRight: '25%', paddingTop: 100}}>
      <ToastContainer/>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input your full name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="nickname"
          label="Nickname"
          tooltip="What do you want others to call you?"
          rules={[{ required: true, message: 'Please input your nickname!', whitespace: true }]}
        >
          <Input />
        </Form.Item>


        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            I have read the <a href="terms-of-service">Terms of service</a>
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
        <Divider/>
        <Form.Item {...tailFormItemLayout}>
          Or if you already have an account <a href="/signin">Sign In</a>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SignUpForm;