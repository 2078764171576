import {AuthenticationDetails} from "amazon-cognito-identity-js";


export const CognitoAuthDetail = (username: string, password: string) => {
    let authenticationData = {
        Username: username,
        Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return authenticationDetails;
}