import {Spin, Table} from "antd";
import * as React from "react";

import {ClockCircleOutlined} from '@ant-design/icons';

export const SignalTable = (props: any) => {
    const loading = !props.data
    return(
        <Table locale = {{emptyText: <div><ClockCircleOutlined />
                <p>
                    There are no current signals,
                    they will appear here as soon as we detect an opportunity.
                </p>
                <p>
                    {"Want to learn more, please read "}
                    <a href="https://blog.tradingadvisor.app/2022/04/my-strategy-with-tradingadvisorapp.html">
                        this article.
                    </a>
                </p>

        </div> }}
               dataSource={ props.data } columns={ props.columns } bordered size="small"
               pagination={ {hideOnSinglePage: true}} loading={loading} />
    )
}