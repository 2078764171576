import * as React from "react";
import {RouteComponentProps} from "react-router-dom";


export default class Landing extends React.Component<RouteComponentProps, any> {
    redirect() {
        if (typeof window !== 'undefined') {
            window.location.href = "https://landing.tradingadvisor.app";
        }
    }
    componentDidMount() {
        this.redirect()
    }

    render() {
        this.redirect()
        return undefined;
    }
}