import {CognitoUserSession} from "amazon-cognito-identity-js";
import {toast} from "react-toastify";
import {CognitoUserInstance} from "./CognitoUserInstance";
import {CognitoAuthDetail} from "./CognitoAuthDetail";

interface credentials {
  username: string;
  password: string;
}
export const CognitoAuth = (props: credentials, onSuccess: Function) => {
  const authenticationDetails = CognitoAuthDetail(props.username, props.password)
  const cognitoUser = CognitoUserInstance(props.username)

  cognitoUser.authenticateUser(authenticationDetails,
      {
        onFailure(err: any): void {
          toast.error(err.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        onSuccess: function (result: CognitoUserSession, userConfirmationNecessary: boolean | undefined): void {
          const accessToken = result.getAccessToken().getJwtToken();
          sessionStorage.setItem('token', JSON.stringify(accessToken));
          toast.success('Successfully logged in.', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true,
            onClose: () => onSuccess(),
            onClick: () =>onSuccess(),
          });
        }
      });
}