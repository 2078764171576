import React, { FunctionComponent } from "react";
import {Menu} from "antd";
import logo from './logo.png'
import {TopMenuProfile} from "./TopMenuProfile";
import {TrackClickHome} from "../../utils/Amplitude";


export const TopMenu: FunctionComponent = () => {
    const HomeButtonClick = () => {
        TrackClickHome()
        window.location.href = '/'
    }
    return (
    <Menu mode="horizontal" theme="dark" style={{display: 'flex', justifyContent: 'space-between'}}>
        <Menu.Item key="home" style={{ flexDirection: 'column' }}>
            <a onClick={HomeButtonClick} >
                <img alt='Logo' src={logo} style={{maxHeight: 50}}/>
            </a>
        </Menu.Item>
        <Menu.Item key="signOut" style={{ flexDirection: 'column', marginLeft: 'auto' }} >
            <TopMenuProfile/>
        </Menu.Item>
    </Menu>
    );
}

export default TopMenu;