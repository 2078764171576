import amplitude from 'amplitude-js';
import {isUserPremium} from "./User";
import {isDevelopmentEnvironment} from "./Enviroment";
import {CurrentUser} from "../components/Auth/Services/CurrentUser";

const init = () => {
    amplitude.init("899f97f727ceeed0fb5a310d20d2e215")
    setUserProperties()
}

const setUserProperties = () => {
    const username = CurrentUser()?.getUsername()
    if (username) {
        amplitude.setUserId(username);
    }
    const userProperties = {
        is_premium: isUserPremium(),
        username: CurrentUser()?.getUsername(),
    };
    amplitude.getInstance().setUserProperties(userProperties);
}

export const TrackEvent = (event: string, eventProperties: {}) => {
    if (isDevelopmentEnvironment()) {
        console.log(`Event: ${ event } with properties: `)
        console.log(eventProperties)
    } else {
        init()
        amplitude.getInstance().logEvent(event, eventProperties);
    }
}

export const TrackVisitHome = (source: string | null = null) => {
    const properties = basicEventProperties(source)

    TrackEvent('visit_home', properties)
}

export const TrackVisitInstrument = (source: string | null = null) => {
    const properties = basicEventProperties(source)

    TrackEvent('visit_isntrument', properties)
}

export const TrackClickUpgrade = (source: string) => {
    const properties = {
        source: source
    }
    TrackEvent('click_upgrade', properties)
}

export const TrackClickHome = () => {
    const properties = basicEventProperties()
    TrackEvent('click_home', properties)
}

export const TrackSignOut = (source: string | null = null) => {
    const properties = basicEventProperties(source)
    TrackEvent('sign_out', properties)
}


export const TrackSignIn = (source: string | null = null) => {
    const properties = basicEventProperties(source)
    TrackEvent('sign_in', properties)
}

export const TrackVisitProfile = (source: string | null = null) => {
    const properties = basicEventProperties(source)
    TrackEvent('sign_in', properties)
}

export const TrackChangePassword = (source: string | null = null, status: string | undefined) => {
    let properties = basicEventProperties(source)
    properties.status = status
    TrackEvent('sign_in', properties)
}

export const TrackSubscription = (source: string | null = null, status: string | undefined) => {
    const properties = basicEventProperties(source)
    properties.status = status
    TrackEvent('subscription', properties)
}

export const TrackClickInstrumentName = (source: string | null = null) => {
    let properties = basicEventProperties(source)
    TrackEvent('click_instrument_name', properties)
}

export const TrackClickSignalSelector = (source: string | null = null) => {
    let properties = basicEventProperties(source)
    TrackEvent('click_signal_selector', properties)
}

interface PropertyObject {
    [key: string]: any
}

const basicEventProperties = (source: string | null = null) => {
    let properties: PropertyObject = {
        is_premium: isUserPremium(),
        source: source
    }

    return properties
}

