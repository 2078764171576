import { Layout } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './SignIn.css'
import SignInForm from '../../../components/Auth/SignInForm/SignInForm';
import TopMenu from '../../../components/TopMenu/TopMenu';
import { Content } from 'antd/lib/layout/layout';

interface IState {
  username: string;
  email: string
}

export default class SignIn extends React.Component<RouteComponentProps, IState> {

  constructor(props: RouteComponentProps<{ username: string}>) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const username = params.get("username");
    const email = params.get("email");
    this.state = {
      username: username || '',
      email: email || '',
    }
  }

  public render() {
    return (
      <Layout style={{height: '100vh'}}>
        <TopMenu/>
        <Content>
          <div>
            <SignInForm email={this.state.email}/>
          </div>
        </Content>
      </Layout>
    );
  }
}
