import React from "react";
import ReactDOM from "react-dom";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
    elderRay,
    ema,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    CandlestickSeries,
    ElderRaySeries,
    LineSeries,
    MovingAverageTooltip,
    OHLCTooltip,
    SingleValueTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    SvgPathAnnotation,
    Annotate
} from "react-financial-charts";


export const InstrumentChart = (instrumentData: any) => {
    const {initialData, signalData} = instrumentData
    const sellDates = signalData.map((val: any): any => {
        if (val['Operation'] === 'SELL') {
            return new Date(val['datetime'])
        }
    });

    const buyDates = signalData.map((val: any): any => {
        if (val['Operation'] === 'BUY') {
            return new Date(val['datetime'])
        }
    });

    const svgBuyAnnotation = {
            fill: "#04f446",
            path: () =>
                "M48 28.001L32 12 16 28.001l4.242 4.242 8.759-8.759V52h6V23.486l8.757 8.757L48 28.001z",
            pathWidth: 32,
            pathHeight: 16,
            tooltip: "Svg Annotation",
            y: ({ yScale, datum }: any) => yScale(datum.low - (datum.low * 0.01)),
        };

    const sameDate = (dateToCheck: Date, dates:[Date]) => {
        for (let actualDate of dates) {
            if(actualDate && (dateToCheck.getDate() === actualDate.getDate()
                && dateToCheck.getMonth() === actualDate.getMonth()
                && dateToCheck.getFullYear() === actualDate.getFullYear()
                && dateToCheck.getHours() === actualDate.getHours()
                )){
                return true;
            }
        }

        return false;
    }

    const svgSellAnnotation = {
        fill: "#ff0404",
        path: () =>
            "M16 36l16 16 16-16-4.242-4.243-8.759 8.759V12h-6v28.514l-8.757-8.757L16 36z",
        pathWidth: 32,
        pathHeight: 16,
        tooltip: "Svg Annotation",
        y: ({ yScale, datum }: any) => yScale(datum.high + (datum.high * 0.01)),
    };

    const sellSignals = (data: any) => {
        const dateToCheck = new Date(data.date)

        return sameDate(dateToCheck, sellDates);
    };

    const buySignals = (data: any) => {
        const dateToCheck = new Date(data.date)

        return sameDate(dateToCheck, buyDates);
    };

    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );
    const height = 300;
    const width = window.innerWidth - window.innerWidth * 0.05;
    const margin = { left: 48, right: 48, top: 0, bottom: 24 };

    const ema12 = ema()
        .id(1)
        .options({ windowSize: 12 })
        .merge((d: any, c: any) => {
            d.ema12 = c;
        })
        .accessor((d: any) => d.ema12);

    const ema26 = ema()
        .id(2)
        .options({ windowSize: 26 })
        .merge((d: any, c: any) => {
            d.ema26 = c;
        })
        .accessor((d: any) => d.ema26);

    const elder = elderRay();

    const calculatedData = elder(ema26(ema12(initialData)));
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        initialData
    );
    const pricesDisplayFormat = format(".4f");
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 100)]);
    const xExtents = [min, max + 5];

    const gridHeight = height - margin.top - margin.bottom;

    const elderRayHeight = 100;
    const elderRayOrigin = (_: any, h: any) => [0, h - elderRayHeight];
    const barChartHeight = gridHeight / 4;
    const barChartOrigin = (_: any, h: any) => [0, h - barChartHeight - elderRayHeight];
    const chartHeight = gridHeight - elderRayHeight;
    const yExtents = (data: {high: number, low: number}) => {
        return [data.high, data.low];
    };
    const dateTimeFormat = "%d %b";
    const timeDisplayFormat = timeFormat(dateTimeFormat);

    const barChartExtents = (data: {volume: number}) => {
        return data.volume;
    };

    const candleChartExtents = (data: {high: number, low: number}) => {
        return [data.high, data.low];
    };

    const yEdgeIndicator = (data: {close: number}) => {
        return data.close;
    };

    const volumeColor = (data: any) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data: any) => {
        return data.volume;
    };

    const openCloseColor = (data: any) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };

    return (
        <ChartCanvas
            height={height}
            ratio={3}
            width={width}
            margin={margin}
            data={data}
            displayXAccessor={displayXAccessor}
            seriesName="Data"
            xScale={xScale}
            xAccessor={xAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
        >
            <Chart
                id={2}
                height={barChartHeight}
                origin={barChartOrigin}
                yExtents={barChartExtents}
            >
                <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
            </Chart>
            <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
                <XAxis showGridLines showTickLabel={false} />
                <YAxis showGridLines tickFormat={pricesDisplayFormat} />
                <CandlestickSeries />
                <LineSeries yAccessor={ema26.accessor()} strokeStyle={ema26.stroke()} />
                <CurrentCoordinate
                    yAccessor={ema26.accessor()}
                    fillStyle={ema26.stroke()}
                />
                <LineSeries yAccessor={ema12.accessor()} strokeStyle={ema12.stroke()} />
                <CurrentCoordinate
                    yAccessor={ema12.accessor()}
                    fillStyle={ema12.stroke()}
                />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />
                <EdgeIndicator
                    itemType="last"
                    rectWidth={margin.right}
                    fill={openCloseColor}
                    lineStroke={openCloseColor}
                    displayFormat={pricesDisplayFormat}
                    yAccessor={yEdgeIndicator}
                />
                <MovingAverageTooltip
                    origin={[8, 24]}
                    options={[
                        {
                            yAccessor: ema26.accessor(),
                            type: "EMA",
                            stroke: ema26.stroke(),
                            windowSize: ema26.options().windowSize
                        },
                        {
                            yAccessor: ema12.accessor(),
                            type: "EMA",
                            stroke: ema12.stroke(),
                            windowSize: ema12.options().windowSize
                        }
                    ]}
                />

                <ZoomButtons />
                <OHLCTooltip origin={[8, 16]} />
                {svgBuyAnnotation && (
                    <Annotate with={SvgPathAnnotation} usingProps={svgBuyAnnotation} when={buySignals} />
                )}

                {svgSellAnnotation && (
                    <Annotate with={SvgPathAnnotation} usingProps={svgSellAnnotation} when={sellSignals} />
                )}
            </Chart>
            <Chart
                id={4}
                height={elderRayHeight}
                yExtents={[0, elder.accessor()]}
                origin={elderRayOrigin}
                padding={{ top: 8, bottom: 8 }}
            >
                <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
                <YAxis ticks={4} tickFormat={pricesDisplayFormat} />

                <MouseCoordinateX displayFormat={timeDisplayFormat} />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />

                <ElderRaySeries yAccessor={elder.accessor()} />
            </Chart>
            <CrossHairCursor />
        </ChartCanvas>
    );
};

