import UserPool from "./UserPool";


export const CurrentUser = () => {
    const user = UserPool.getCurrentUser()
    if(user){
        user.getSession(function () {});
    }

    return(user)
}


export const CurrentUserData = ({callbackFunction}: { callbackFunction: Function }) => {
    const user = CurrentUser()
    const attributes: { [k: string]: string } = {};
    if(user){
        return user.getSession(function () {
            user.getUserAttributes( (err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                result?.forEach(item => {
                    let name = item.getName()
                    attributes[name] = item.getValue()
                });

                callbackFunction(attributes)
            });
        });
    } else {
        return undefined;
    }
}

export const CurrentUserAccessToken = () => {
    return CurrentUser()?.getSignInUserSession()?.getAccessToken().getJwtToken()
}