import { Layout } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TopMenu from '../../components/TopMenu/TopMenu';

export default class TermsOfService extends React.Component<RouteComponentProps> {

    public render() {
        return (
            <Layout>
                <TopMenu/>
                <div style={{padding: '5%', fontSize: 14}}>
                    <h1>Terms and Conditions of Use</h1>

                    <h2>1. Terms</h2>

                    <p>By accessing this Website, accessible from https://www.tradingadvisor.app, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>

                    <h2>2. Use License</h2>

                    <p>Permission is granted to temporarily download one copy of the materials on Trading Advisor's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

                    <ul>
                        <li>modify or copy the materials;</li>
                        <li>use the materials for any commercial purpose or for any public display;</li>
                        <li>attempt to reverse engineer any software contained on Trading Advisor's Website;</li>
                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                        <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                    </ul>

                    <p>This will let Trading Advisor to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

                    <h2>3. Disclaimer</h2>

                    <p>All the materials on Trading Advisor’s Website are provided "as is". Trading Advisor makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Trading Advisor does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

                    <p>
                        This website and all information is intended for educational purposes only and does not give
                        financial advice. Trading Advisor App is not a service to provide legal and financial advice;
                        any information provided here is only the personal opinion of the author
                        (not advice or financial advice in any sense, and in the sense of any act,
                        ordinance or law of any country) and must not be used for financial activities.
                        Trading Advisor App does not offer, operate or provide financial, brokerage,
                        commercial or investment services and is not a financial advisor. Rather,
                        Trading Advisor App is an educational site and a platform for exchanging Market analysis information.
                        Whenever information is disclosed, whether express or implied, about profit or revenue,
                        it is not a guarantee. No method or trading system ensures that it will generate a profit,
                        so always remember that trade can lead to a loss.
                        Trading responsibility, whether resulting in profits or losses, is yours and you must agree not to hold
                        Trading Advisor App or other information providers that are responsible in any way whatsoever.
                        The use of the system means that the user accepts Disclaimer and Terms of Use.
                    </p>
                    <p>
                        Trading Advisor App is not represented as a registered investment consultant or brokerage dealer
                        nor offers to buy or sell any of the financial instruments mentioned in the service offered.
                    </p>
                    <p>
                        While Trading Advisor App believes that the content provided is accurate, there are no explicit or
                        implied warranties of accuracy. The information provided is believed to be reliable;
                        Trading Advisor App does not guarantee the accuracy or completeness of the information provided.
                        Third parties refer to Trading Advisor App to provide technology and information if a third party fails,
                        and then there is a risk that the information may be delayed or not delivered at all.
                    </p>
                    <p>
                        All information and comments contained on this website, including but not limited to, opinions,
                        analyzes, news, prices, research, and general, do not constitute investment advice or an invitation
                        to buy or sell any type of instrument. Trading Advisor App assumes no responsibility for any loss or
                        damage that may result, directly or indirectly, from the use or dependence on such information.
                    </p>
                    <p>
                        All information contained on this web site is a personal opinion or belief of the author.
                        None of these data is a recommendation or financial advice in any sense,
                        also within the meaning of any commercial act or law. Writers,
                        publishers and affiliates of Trading Advisor App are not responsible for your trading in any way.
                    </p>
                    <p>
                        The information and opinions contained in the site are provided for information only and for educational
                        reasons, should never be considered as direct or indirect advice to open a trading account and /
                        or invest money in Market trading . Trading Advisor App assumes no responsibility for any decisions
                        taken by the user to create a merchant account with any of the brokers listed on this website.
                        Anyone who decides to set up a merchant account or use the services, free of charge or paid,
                        to any of the broker companies mentioned on this website, bears full responsibility for their actions.
                    </p>
                    <p>
                        Any institution that offers a service and is listed on this website, including forex brokers,
                        financial companies and other institutions, is present only for informational purposes.
                        All ratings, ratings, banners, reviews, or other information found for any of the above-mentioned
                        institutions are provided in a strictly objective manner and according to the best possible
                        reflection of the materials on the official website of the company.
                    </p>

                    <p>
                        Market trading is potentially high risk and may not be suitable for all investors.
                        The high level of leverage can work both for and against merchants.
                        Before each Market investment, you should carefully consider your goals, past experience and risk level.
                        The opinions and data contained on this site should not be considered as suggestions or advice for the
                        sale or purchase of currency or other instruments. Past results do not show or guarantee future results.
                    </p>

                    <p>
                        Neither Trading Advisor App nor its affiliates ensure the accuracy of the content provided on this Site.
                        You explicitly agree that viewing, visiting or using this website is at your own risk.
                    </p>

                    <h2>4. Limitations</h2>

                    <p>Trading Advisor or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Trading Advisor’s Website, even if Trading Advisor or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

                    <h2>5. Revisions and Errata</h2>

                    <p>The materials appearing on Trading Advisor’s Website may include technical, typographical, or photographic errors. Trading Advisor will not promise that any of the materials in this Website are accurate, complete, or current. Trading Advisor may change the materials contained on its Website at any time without notice. Trading Advisor does not make any commitment to update the materials.</p>

                    <h2>6. Links</h2>

                    <p>Trading Advisor has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Trading Advisor of the site. The use of any linked website is at the user’s own risk.</p>

                    <h2>7. Site Terms of Use Modifications</h2>

                    <p>Trading Advisor may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

                    <h2>8. Your Privacy</h2>

                    <p>Please read our <a href="/privacy-policy">Privacy Policy.</a></p>

                    <h2>9. Governing Law</h2>

                    <p>Any claim related to Trading Advisor's Website shall be governed by the laws of cl without regards to its conflict of law provisions.</p>

                    <h2>10. Payments and refund</h2>

                    <p><b>10.1 Paid service:</b> Users can upgrade their accounts to a paid service by buying a monthly or annual subscription.</p>
                    <p><b>10.2 Cancellation:</b> Users can cancel their subscription any time and their account will be downgraded to a free version.</p>
                    <p><b>10.3 Refund:</b> We do not offer refunds.</p>
                </div>
            </Layout>
        );
    }
}
