import React from "react";
import {RouteComponentProps} from "react-router-dom";
import TopMenu from "../components/TopMenu/TopMenu";
import AppFooter from "../components/AppFooter/AppFooter";
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import SubscriptionContainer from "../components/Subscription/SubscriptionContainer";
import {TrackClickUpgrade} from "../utils/Amplitude";

export default class Subscribe extends React.Component<RouteComponentProps> {
    public render() {
        return(
            <Layout>
                <TopMenu/>
                <Content className="site-layout-background">
                    <SubscriptionContainer/>
                </Content>
                <AppFooter/>
            </Layout>
        )
    }
}

export const UpgradeButtonAction = (source: string) => {
    TrackClickUpgrade(source)
    window.location.href = '/subscribe'
}