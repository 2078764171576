import {CognitoUser} from "amazon-cognito-identity-js";
import UserPool from "./UserPool";


export const CognitoUserInstance = (username: string) => {
    const userData = {
        Username: username,
        Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return cognitoUser;
}