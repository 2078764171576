import { Layout } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TopMenu from '../../../components/TopMenu/TopMenu';
import { Content } from 'antd/lib/layout/layout';
import {ProfileForm} from "../../../components/Auth/ProfileForm/ProfileForm";

export default class ForgotPassword extends React.Component<RouteComponentProps> {
    public render() {
        return (
            <Layout style={{height: '100vh'}}>
                <TopMenu/>
                <Content>
                    <div>
                        <ProfileForm/>
                    </div>
                </Content>
            </Layout>
        );
    }
}
