import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import axios from 'axios';
import {Table, Typography, Divider, Layout} from 'antd';
import './Instrument.css'
import {Content} from 'antd/lib/layout/layout';
import TopMenu from '../../components/TopMenu/TopMenu';
import AppFooter from '../../components/AppFooter/AppFooter';
import {Chart} from "../../components/Chart/Chart";
import {InstrumentColumn} from "../../components/SignalTable/InstrumentColumn";
import {TrackVisitInstrument} from "../../utils/Amplitude";

const {Paragraph} = Typography;


interface IState {
    signals: any;
    symbol: any;
    name: any;
    data: any;
    type: any;
}

export default class Home extends React.Component<RouteComponentProps, IState> {
    columns = InstrumentColumn

    pagination = {label: 'none', value: 'none'}

    constructor(props: RouteComponentProps<{ symbol?: string }>) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const symbol = params.get("symbol");
        const name = params.get("name");
        const type = params.get("type");

        this.state = {
            signals: [],
            name: name,
            symbol: symbol,
            data: {},
            type: type
        }

        TrackVisitInstrument()
    }

    public componentDidMount(): void {
        const parameters = this.state.symbol == null ? `name=${ this.state.name }` : `symbol=${ this.state.symbol }`
        const url = `${ process.env.REACT_APP_API_URL }/instrument?${ parameters }`
        axios.get(url).then(Response => {
            this.setState({signals: Response.data.body.values,
                                 name: Response.data.body.name})
        })
    }

    public render() {
        return (
            <Layout>
                <TopMenu/>
                <Content className="site-layout-background">
                    <div style={ {padding: '1%'} }>
                        <Divider>{ this.state.name } (Last 14 days data)</Divider>
                        <Chart
                            type={this.state.type}
                            name={this.state.name}
                            from={null}
                            to={null}
                            interval={60}
                            signalData={this.state.signals}
                        />
                        <Divider>Signal History</Divider>
                        <Table dataSource={ this.state.signals } columns={ this.columns } bordered size="small"
                               pagination={ {hideOnSinglePage: true} }/>

                    </div>
                </Content>
                <AppFooter/>
            </Layout>)
    }
}
